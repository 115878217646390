export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

//set default velue for empty properties for JSON object/array.
//we use this for general study validations. hours, gpa, upperdivision hours
export const addJsonPropertyWithDefault = (json, properties) => {
  if (Array.isArray(json)) {
    return json.map((obj) => {
      const clonedObj = { ...obj }; // Clone the object
      properties.forEach((property) => {
        if (!clonedObj[property]) {
          clonedObj[property] = 0;
        }
      });
      return clonedObj;
    });
  } else {
    const clonedObj = { ...json };
    properties.forEach((property) => {
      if (!clonedObj[property]) {
        clonedObj[property] = 0;
      }
    });
    return clonedObj;
  }
};

export const createDoughnutData = (current = 0, total = 0, error = false) => {
  let fulfilled = 0;
  let remainder = 100;

  if (current > 0 && total > 0) {
    fulfilled = Math.round((current / total) * 100);
    remainder = 100 - fulfilled;

    fulfilled = fulfilled >= 100 ? 100 : fulfilled;
    remainder = remainder <= 0 ? 0 : remainder;
  }

  return {
    datasets: [
      {
        data: [fulfilled, remainder],
        backgroundColor: [error ? '#bd4800' : '#78BE20', '#E8E8E8'],
        borderWidth: 0,
      },
    ],
  };
};
