import React, {
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Button } from '@asu/components-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faGripVertical,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { classNames } from 'utils';
import StaticData from './StaticData';
import MultipleCourseList from './RequirementMultipleCourseList';
import {
  // closestCenter,
  closestCorners,
  DndContext,
  // DragOverlay,
  KeyboardSensor,
  PointerSensor,
  // useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

const Sortable = ({ id, children, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: 'white',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      role={!disabled ? 'button' : undefined}
    >
      {children}
    </div>
  );
};

const CourseListComponent = forwardRef(
  (
    {
      id, //checksheet id
      year,
      courseListId,
      edit = false,
      openEditModal = null,
      // updateData = null,
      dataFromParent = null,
      // dataToParent = null,
      // createWrappedData = null,
      fromCourseList = false,
      courses,
      setCourses,
      isLocked = false,
      holdsLock = false,
      autoSaveNewItem = null, // function to save new item when click "add new item" button
    },
    ref
  ) => {
    const [showEditCourse, setShowEditCourse] = useState(false);
    const [newRequirement, setNewRequirement] = useState(false);

    // const courseLists = useSelector((state) => state.checksheet.courseLists);

    // const courseList = useMemo(
    //   () =>
    //     !edit
    //       ? courseLists[courseListId]
    //       : {
    //           courses: courses,
    //           courseListId: courseListId,
    //           checksheetId: id,
    //         },
    //   [courses, courseLists, edit, id, courseListId]
    // );

    // console.log('courseList-----', courseList);

    // when click the new list item, save the current requirement before adding a new requirement
    // to save the requirement, call the child function
    const multipleCourseListRef = useRef(null); // Create a ref
    const saveRequirement = () => {
      if (multipleCourseListRef.current) {
        multipleCourseListRef.current.callChildFunction(
          'Hello from courselist component'
        ); // Call child function
      }
    };

    const handleNewItemClick = () => {
      saveRequirement();
      setShowEditCourse(false);
      setNewRequirement(true);
      autoSaveNewItem();
    };

    const handleEditClick = (reqIndex, itemType, req) => {
      setShowEditCourse(true);
      setNewRequirement(false);
      openEditModal(reqIndex, itemType, req);
    };

    const handleCloseEditCourse = () => {
      setShowEditCourse(false);
      setNewRequirement(false);
    };

    const CourseListDetails = ({
      id,
      courses,
      setCourses,
      edit,
      openEditModal = null,
      // updateData = null,
      division,
      isLocked = false,
      holdsLock = false,
    }) => {
      // console.log('courseList::::::', courses);
      console.log('Editing course: ', showEditCourse);

      const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 10,
          },
        }),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
      );

      const handleDragEnd = async (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
          setCourses((items) => {
            const activeIndex = items.findIndex(
              (req) => req.requirementId === active.id
            );
            const overIndex = items.findIndex(
              (req) => req.requirementId === over.id
            );

            return arrayMove(items, activeIndex, overIndex);
          });

          //   updatedSubsections[activeSubsectionId] = activeSubsection;
          //   if (checksheet && isChecksheetRequirement) {
          //     const updatedRequirementList = { ...checksheetRequirementList };
          //     const updatedChecksheetSection = { ...updatedRequirementList[id] };
          //     updatedChecksheetSection.subsections = updatedSubsections;
          //     updatedRequirementList[id] = updatedChecksheetSection;
          //     dispatch(setChecksheetRequirementList(updatedRequirementList));
          //     const jsonData = {
          //       year: checksheetState.year.value,
          //       templateId: checksheetState.templateId,
          //       checksheetName: checksheetState.checksheetName,
          //       programType: checksheetState.programType,
          //       acadplan: checksheetState.acadplan,
          //       acadsubplan: checksheetState.acadsubplan,
          //       college: checksheetState.college,
          //       department: checksheetState.department,
          //       componentRequirements: updatedRequirementList,
          //       courseLists: checksheetState.courseLists,
          //     };
          //     console.log(jsonData);
          //     const token = await getAccessToken();
          //     updateChecksheet({
          //       id: checksheetState.checksheetId,
          //       jsonData,
          //       token,
          //     });
          //   } else dispatch(setSubsections(updatedSubsections));
        }
      };

      // const removeRow = (index, type, id) => {
      //   const confirmDeletion = window.confirm(
      //     'Are you sure you want to delete this data?'
      //   );
      //   if (confirmDeletion) {
      //     updateData(index, type, null, id);
      //   }
      // };

      const handleRemoveRow = (reqId) => {
        const next = [...courses];

        const foundIndex = next.findIndex((req) => req.requirementId === reqId);

        next.splice(foundIndex, 1);

        setCourses([...next]);
      };

      const RequirementRow = ({
        req,
        reqIndex,
        edit,
        openEditModal,
        division,
        isLocked = false,
        holdsLock = false,
      }) => {
        // console.log('req', req);
        //filter by division
        let showRequirement = true;

        if (division && division.trim()) {
          showRequirement = false;

          if (req.courses && req.courses.length > 0) {
            req.courses.forEach((course) => {
              if (course.subject && course.number) {
                if (
                  (course.number >= 100 &&
                    course.number < 200 &&
                    division === '100') ||
                  (course.number >= 200 &&
                    course.number < 300 &&
                    division === '200') ||
                  (course.number >= 300 &&
                    course.number < 400 &&
                    division === '300') ||
                  (course.number >= 400 &&
                    course.number < 500 &&
                    division === '400') ||
                  (course.number < 300 && division === 'Lower') ||
                  (course.number >= 300 && division === 'Upper')
                ) {
                  showRequirement = true;
                }
              } else if (division === course.division) {
                showRequirement = true;
              }
            });
          }
        }

        return (
          <>
            {showRequirement && (
              <div
                key={reqIndex}
                // className="row px-1 py-1 m-0 border-top border-gray-2 bg-gray-1"
                className={classNames(
                  isLocked && holdsLock && 'hoverable',
                  'row py-2 mx-0 border border-top-0 border-gray-2'
                )}
              >
                <div
                  style={{
                    flexBasis: 'auto',
                    width: '100%',
                  }}
                  className="d-flex gap-2"
                >
                  {isLocked && holdsLock ? (
                    <FontAwesomeIcon
                      icon={faGripVertical}
                      style={{ width: '15px' }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faLock}
                      style={{ width: '15px', paddingTop: '2px' }}
                    />
                  )}

                  <div
                    // className={classNames(!isLocked && 'pe-none')}
                    style={{ width: '100%' }}
                    onClick={
                      isLocked && holdsLock
                        ? () => handleEditClick(reqIndex, req.itemType, req)
                        : undefined
                    }
                    // onMouseEnter={() =>
                    //   handleEditClick(reqIndex, req.itemType, req)
                    // }
                    role={isLocked && holdsLock ? 'button' : undefined}
                  >
                    {req.itemType === 'req_multiple' &&
                      req.courses.map((course, index) => (
                        <Fragment key={index}>
                          {course.connector && (
                            <div
                              className={classNames(
                                course.connector,
                                course.connector === 'or' && 'ms-0',
                                'my-1 fw-bold rounded-1 text-nowrap'
                              )}
                            >
                              {course.connector}
                            </div>
                          )}
                          <div className="d-flex">
                            <div
                              key={index}
                              className={classNames(
                                isLocked && holdsLock && 'hover-text'
                              )}
                              style={{ width: '90%' }}
                            >
                              {course.itemType === 'req_check' &&
                              course.checkType === 'gpa' ? (
                                <>
                                  <span className="fw-bold">Check</span>:{' '}
                                </>
                              ) : (
                                course.checkType === 'milestone' && (
                                  <>
                                    <span className="fw-bold">Milestone</span>:{' '}
                                  </>
                                )
                              )}
                              {course.gpa && `Minimum ${course.gpa} `}
                              {course.checkSubtype === 'asu_cum_gpa' &&
                                'ASU Cumulative GPA'}
                              {course.checkSubtype === 'major_gpa' &&
                                'Major GPA'}
                              {(course.checkSubtype === 'subject' ||
                                course.checkSubtype === 'courses') &&
                                `GPA in `}
                              {course.checkSubtype === 'subject' && 'all '}
                              {course.checkSubtype === 'courses' &&
                                course.checkCourses &&
                                course.checkCourses.map(
                                  (checkCourse, index, array) => (
                                    <span key={index}>
                                      {index !== 0 &&
                                        index === array.length - 1 &&
                                        'and '}
                                      {checkCourse.subject} {checkCourse.number}
                                      {array.length > 2 &&
                                      index < array.length - 1
                                        ? ', '
                                        : ' '}
                                    </span>
                                  )
                                )}
                              {course.milestone && (
                                <span>
                                  {Object.values(course.milestone)[0]}
                                </span>
                              )}
                              {course.subject && (
                                <span
                                  className={classNames(
                                    course.itemType !== 'req_check' && 'fw-bold'
                                  )}
                                >
                                  {course.subject}{' '}
                                </span>
                              )}
                              {course.number && (
                                <>
                                  <span className="fw-bold">
                                    {course.number}
                                  </span>
                                  :{' '}
                                </>
                              )}
                              {course?.topic?.description ? (
                                course.topic.description
                              ) : course.proposedTitle ? (
                                <span>
                                  {course.proposedTitle}
                                  <span className="text-warning-custom bg-warning-custom fw-bold mx-1 px-1">
                                    Proposed
                                  </span>
                                </span>
                              ) : (
                                course.description
                              )}
                              {!!course.division && course.division.trim()
                                ? course.division === 'Upper' ||
                                  course.division === 'Lower'
                                  ? course.division + ' Division '
                                  : course.division + '-Level '
                                : ''}
                              {course.checkSubtype === 'subject' && ' courses'}
                              {course.group && course.group + ': '}
                              {course.groupIndex !== 'custom_text' &&
                                course.text}
                              {course.customText}
                              {course.gs && course.subject && (
                                <span> ({course.gs})</span>
                              )}
                              {course.itemType === 'req_gs' && !!course.gs && (
                                <>
                                  {
                                    StaticData.gsList.find(
                                      (gs) => gs.value === course.gs
                                    ).label
                                  }{' '}
                                  ({course.gs})
                                </>
                              )}
                              {(course.itemType === 'req_elective' ||
                                course.itemType === 'req_subject') &&
                                'Elective'}
                              {course?.recommended?.courses.length > 0 && (
                                <>
                                  <br />
                                  <span className="ms-3">
                                    <span className="fst-italic">
                                      Recommended:
                                    </span>
                                    {course.recommended.courses.map(
                                      (c, ind, array) => (
                                        <span key={ind}>
                                          {ind !== 0 &&
                                            !c.connector?.trim(' ') &&
                                            'or '}
                                          {c.connector} {c.subject} {c.number}
                                          {!!c.proposedTitle && (
                                            <span className="text-warning-custom bg-warning-custom fw-bold mx-1 px-1">
                                              Proposed
                                            </span>
                                          )}{' '}
                                        </span>
                                      )
                                    )}
                                  </span>
                                </>
                              )}
                            </div>
                            <div
                              className="me-1 text-end"
                              style={{ width: '10%' }}
                            >
                              {(course.maxHours &&
                              course.hours !== course.maxHours
                                ? `${course.hours} - ${course.maxHours}`
                                : course.hours) ||
                                (course.itemType !== 'req_course' &&
                                  course.itemType !== 'req_text' &&
                                  'Variable')}
                            </div>
                          </div>
                        </Fragment>
                      ))}
                  </div>
                  {isLocked && holdsLock && (
                    <div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleRemoveRow(req.requirementId)}
                        className="deleteButton"
                        title="Delete item"
                      />
                    </div>
                  )}
                </div>
                {/* <div
                style={{ flexBasis: 'auto', width: '9%' }}
                className="d-flex justify-content-end align-items-center"
              >
                <div
                  className={classNames(
                    !!req.hours
                      ? 'bg-gray-2 fw-semibold lh-1 rounded-pill'
                      : 'text-gray-4',
                    'text-nowrap text-end'
                  )}
                  style={{
                    fontSize: '14px',
                    height: 'fit-content',
                    padding: '5px 8px',
                  }}
                ></div>
              </div>
              {edit && (
                <div
                  style={{ flexBasis: 'auto', width: '6%' }}
                  className="d-flex justify-content-end align-items-center"
                ></div>
              )} */}
              </div>
            )}
          </>
        );
      };

      return (
        <div className="mb-2">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            // onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext
              items={courses.map((req) => req.requirementId)}
              strategy={verticalListSortingStrategy}
            >
              {courses &&
                courses.length > 0 &&
                courses.map(
                  (req, reqIndex) =>
                    req !== null &&
                    req.itemType === 'req_multiple' && (
                      <Sortable
                        key={req.requirementId}
                        id={req.requirementId}
                        disabled={showEditCourse || !holdsLock}
                      >
                        <RequirementRow
                          req={req}
                          reqIndex={reqIndex}
                          edit={edit}
                          openEditModal={openEditModal}
                          division={division}
                          isLocked={isLocked}
                          holdsLock={holdsLock}
                        />

                        {reqIndex === dataFromParent.index &&
                          showEditCourse && (
                            <MultipleCourseList
                              ref={multipleCourseListRef}
                              year={year}
                              dataFromParent={dataFromParent}
                              closeEditModal={handleCloseEditCourse}
                              newRequirement={false}
                              courses={courses}
                              setCourses={setCourses}
                            />
                          )}
                      </Sortable>
                    )
                )}
              {/* new requirement when user click on new list item */}
              {newRequirement && (
                <MultipleCourseList
                  ref={multipleCourseListRef}
                  dataFromParent={{
                    index: courses.length || 0,
                    type: 'req_multiple',
                    details: {},
                  }}
                  year={year}
                  modal={true}
                  // dataToParent={dataToParent}
                  // createWrappedData={createWrappedData}
                  closeEditModal={handleCloseEditCourse}
                  newRequirement={true}
                  courses={courses}
                  setCourses={setCourses}
                />
              )}
            </SortableContext>
          </DndContext>
        </div>
      );
    };

    const getRequirementText = (req) => {
      let division = '';
      let checkCourses = '';

      if (req.division?.trim()) {
        division =
          req.division === 'Upper' || req.division === 'Lower'
            ? req.division + ' Division '
            : req.division + '-Level ';
      }

      if (
        req.checkType === 'gpa' &&
        req.checkSubtype === 'courses' &&
        !!req.checkCourses?.length
      ) {
        req.checkCourses.forEach((checkCourse, index, array) => {
          if (index !== 0 && index === array.length - 1) checkCourses += 'and ';

          checkCourses += checkCourse.subject + ' ' + checkCourse.number;

          if (array.length > 2 && index < array.length - 1)
            checkCourses += ', ';
          else checkCourses += ' ';
        });
      }

      if (req.itemType === 'req_course')
        return `${req.subject} ${req.number}: ${req.topic?.description ?? !!req.proposedTitle ? req.proposedTitle : req.description}`;
      if (req.itemType === 'req_gs') return `${division}${req.gs}`;
      if (req.itemType === 'req_elective')
        return `${req.subject ? req.subject + ' ' : ''}${division}Elective`;
      if (req.itemType === 'req_text')
        return `${division}${req.group ? req.group + ': ' : ''}${req.groupIndex === 'custom_text' ? req.customText : req.text}`;
      if (req.itemType === 'req_check') {
        const textDisplay = `${req.checkType === 'gpa' ? 'Check: ' : req.checkType === 'milestone' ? 'Milestone: ' : ''}${
          req.gpa ? `Minimum ${req.gpa} ` : ''
        }${req.checkSubtype === 'asu_cum_gpa' ? 'ASU Cumulative GPA' : ''}${
          req.checkSubtype === 'major_gpa' ? 'Major GPA' : ''
        }${req.checkSubtype === 'subject' ? 'GPA in all ' + req.subject + ' ' + division + 'courses' : ''}${
          req.checkSubtype === 'courses' ? `GPA in ${checkCourses}` : ''
        }${req.milestone ? `${Object.values(req.milestone)[0]}` : ''}`;
        console.log('Text displayed: ', textDisplay);
        return textDisplay;
      }

      return null;
    };

    const handleChangeSort = (e) => {
      const { value } = e.target;
      let next = [...courses];

      switch (value) {
        case 'AToZ':
          next = next.sort((a, b) => {
            let courseIndex = 0;
            let equal = 0;

            while (
              courseIndex < a.courses.length &&
              courseIndex < b.courses.length &&
              equal === 0
            ) {
              equal = getRequirementText(a.courses[courseIndex]).localeCompare(
                getRequirementText(b.courses[courseIndex])
              );
              courseIndex++;
            }

            if (equal === 0) {
              if (a.courses.length < b.courses.length) equal = -1;
              else equal = 1;
            }

            return equal;
          });
          break;
        case 'ZToA':
          next = next.sort((a, b) => {
            let courseIndex = 0;
            let equal = 0;

            while (
              courseIndex < a.courses.length &&
              courseIndex < b.courses.length &&
              equal === 0
            ) {
              equal = getRequirementText(b.courses[courseIndex]).localeCompare(
                getRequirementText(a.courses[courseIndex])
              );
              courseIndex++;
            }

            if (equal === 0) {
              if (a.courses.length < b.courses.length) equal = 1;
              else equal = -1;
            }

            return equal;
          });
          break;
        default:
          break;
      }

      setCourses(next);
    };

    // Function you want to expose to the parent (courseListComponent)
    const saveCourses = (message) => {
      console.log('message:', message);
      handleNewItemClick();
    };

    // Use useImperativeHandle to expose childFunction to the parent
    useImperativeHandle(ref, () => ({
      callChildFunction(message) {
        saveCourses(message);
      },
    }));

    useEffect(() => {}, [courses]);

    return (
      <div>
        <div className="d-flex gap-2 justify-content-between align-items-center mb-4">
          <div className="fs-4 fw-bold">Items</div>
          {isLocked && holdsLock && (
            <div className="d-flex gap-2 align-items-center">
              <div className="fw-bold">Sort by</div>
              <select onChange={(e) => handleChangeSort(e)}>
                <option value="">{'\u2014'}</option>
                <option value="AToZ">A-Z</option>
                <option value="ZToA">Z-A</option>
              </select>
            </div>
          )}
        </div>
        {/* ======  Requirements table ====== */}
        <div className="w-100 ">
          <div className="row px-0 pb-1 m-0 fw-bold border-bottom border-2 border-gray-7">
            <div
              className="ps-0"
              style={{
                flexBasis: 'auto',
                width: '90%',
              }}
            >
              List item
            </div>
            <div
              style={{ flexBasis: 'auto', width: '10%' }}
              className={classNames('pe-0', 'text-end text-nowrap')}
            >
              Credit hours
            </div>
            {edit && <div style={{ flexBasis: 'auto', width: '6%' }} />}
          </div>

          <CourseListDetails
            id={id}
            courses={courses}
            setCourses={setCourses}
            edit={true}
            openEditModal={openEditModal}
            // updateData={updateData}
            isLocked={isLocked}
            holdsLock={holdsLock}
          />
        </div>
        {isLocked && holdsLock && (
          <div className="d-flex py-2 mb-5 gap-1">
            <Button
              onClick={handleNewItemClick}
              color="gold"
              label="New item"
              size="small"
            />
          </div>
        )}
      </div>
    );
  }
);

export default CourseListComponent;
