import React, { useEffect, useState } from 'react';
import { Accordion } from '@asu/components-core';
import { fetchDplColleges, fetchDplUndergradPrograms } from 'apis/dplAPIs';
import { useQuery } from '@tanstack/react-query';
import { subplanActivationGetAll, subplanActivation } from 'apis/adminAPIs';
import useServiceAuth from 'auth/useServiceAuth';
import { setYear } from 'state/slices/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import StaticData from '../components/StaticData';

// import axios from 'axios';

const SubplanActivation = () => {
  const [error, setError] = useState('');
  const [colleges, setColleges] = useState([]);
  // const [programs, setPrograms] = useState([]);
  const [subplans, setSubplans] = useState([]);
  const [subplanActivationList, setSubplanActivationList] = useState([]);
  const { isAuthenticated, getAccessToken } = useServiceAuth();
  const year = useSelector((state) => state.settings.year);
  const dispatch = useDispatch();

  const {
    data: fetchCollegesData,
    error: fetchCollegesError,
    isError: fetchCollegesIsError,
    isSuccess: fetchCollegesIsSuccess,
    isPending: fetchCollegesIsPending,
    fetchStatus: fetchCollegesFetchStatus,
  } = useQuery({
    queryKey: ['colleges'],
    queryFn: fetchDplColleges,
  });

  const {
    data: fetchProgramsData,
    error: fetchProgramsError,
    isError: fetchProgramsIsError,
    isSuccess: fetchProgramsIsSuccess,
    isPending: fetchProgramsIsPending,
    fetchStatus: fetchProgramsFetchStatus,
  } = useQuery({
    queryKey: ['programs'],
    queryFn: fetchDplUndergradPrograms,
  });

  const {
    data: fetchSubplanActivationListData,
    error: fetchSubplanActivationListError,
    isError: fetchSubplanActivationListIsError,
    isSuccess: fetchSubplanActivationListIsSuccess,
    // isPending: fetchSubplanActivationListIsPending,
    // fetchStatus: fetchSubplanActivationListFetchStatus,
  } = useQuery({
    queryKey: ['subplanActivationList', year],
    queryFn: async () => {
      const token = await getAccessToken();
      return subplanActivationGetAll({ year: year, token });
    },
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (fetchCollegesError) setError(fetchCollegesError.message);
    if (fetchCollegesIsSuccess) {
      const colleges = fetchCollegesData;
      setColleges(sortByAlphaSortDescription(colleges));
    }
  }, [fetchCollegesData, fetchCollegesError, fetchCollegesIsSuccess, year]);

  useEffect(() => {
    if (fetchProgramsError) setError(fetchProgramsError.message);
    if (fetchProgramsIsSuccess) {
      const programs = fetchProgramsData;

      if (fetchSubplanActivationListError)
        setError(fetchSubplanActivationListError.message);
      if (fetchSubplanActivationListIsSuccess) {
        const subplanActivationList = fetchSubplanActivationListData;
        setSubplanActivationList(subplanActivationList);
      }

      setSubplans(mapByCollegeAcadOrg(programs, subplanActivationList));
    }
  }, [
    fetchProgramsData,
    fetchProgramsError,
    fetchProgramsIsSuccess,
    subplanActivationList,
    fetchSubplanActivationListData,
    fetchSubplanActivationListError,
    fetchSubplanActivationListIsSuccess,
    year,
  ]);

  useEffect(() => {
    const checkboxes = document.querySelectorAll('.form-check-input');
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', handleUpdateSubplan);
    });

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      checkboxes.forEach((checkbox) => {
        checkbox.removeEventListener('change', handleUpdateSubplan);
      });
    };
  }, [subplans, year]);

  const handleSetYear = (e) => {
    dispatch(setYear(e.target.value));
  };

  if (
    (fetchCollegesIsPending && fetchCollegesFetchStatus === 'fetching') ||
    (fetchProgramsIsPending && fetchProgramsFetchStatus === 'fetching')
  )
    return <div className="container">Loading...</div>;

  if (
    fetchCollegesIsError ||
    fetchProgramsIsError ||
    fetchSubplanActivationListIsError
  )
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <div className="container mb-5 mt-5">
      <div className="row">
        <div className="col-md-8">
          <h3>Subplans </h3>
        </div>
        <div className="col-md-4">
          <div className="d-flex gap-2">
            <div className="fw-bold">Catalog year</div>
            <select value={year} onChange={(e) => handleSetYear(e)}>
              {StaticData.yearList.map((year) => (
                <option key={year.value} value={year.value}>
                  {year.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <p>
        Select a college/school to view subplans. Turn on a subplan to allow a
        checksheet to be created for that subplan.
      </p>
      <div className="custom-accordion">
        {colleges && colleges.length > 0 && (
          <Accordion cards={getTableData(colleges, subplans, year)} />
        )}
      </div>
    </div>
  );
};

export default SubplanActivation;

const getTableData = (collegeList, subplanList, year) => {
  const tableData = [];
  if (collegeList && collegeList !== null) {
    collegeList.forEach((college) => {
      const collegeData = {
        content: {
          body: getBody(subplanList, college.acadOrgCode, year),
          header: college.alphaSortDescription,
        },
      };

      tableData.push(collegeData);
    });
  }
  return tableData;
};

const getBody = (subplanList, thisCollege, year) => {
  let body = '';
  if (subplanList && subplanList !== null && subplanList.size > 0) {
    let subplansByCollege = subplanList.get(thisCollege);
    if (subplansByCollege) {
      subplansByCollege.forEach((plan) => {
        body += `<div class="row pt-5 pb-2">
            <div class="col-md-6 fw-bold"> ${plan.acadPlanDescription}</div>
            <div class="col-md-6"> ${plan.acadPlanCode}</div>
          </div>`;

        for (let i = 0; i < plan.subplans.length; i++) {
          let checked = plan.subplans[i].isActive ? 'checked' : '';
          let label = plan.subplans[i].isActive ? 'Active' : 'Inactive';

          body += `<div class="row">
            <div class="col-md-6"> ${plan.subplans[i].description}</div>
            <div class="col-md-4"> ${plan.subplans[i].acadSubPlanCode}</div>
            <div class="col-md-2">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="switchControl${plan.subplans[i].acadSubPlanCode}" ${checked} 
                  value="${plan.acadPlanCode} ${plan.subplans[i].acadSubPlanCode} ${year}"
                  data-ga-input="checkbox"
                  data-ga-input-name="onclick"
                  data-ga-input-event="select"
                  data-ga-input-action="click"
                  data-ga-input-region="main content"
                  data-ga-input-section="${plan.subplans[i].acadSubPlanCode}">
                <label class="form-check-label" for="switchControl${plan.subplans[i].acadSubPlanCode}" >${label}</label>
              </div>
            </div>
          </div>`;
        }
      });
    }
  }

  return body;
};

const mapByCollegeAcadOrg = (programs, subplanActivationList) => {
  const result = new Map();

  if (!!programs.length) {
    programs.forEach((obj) => {
      if (obj.owners) {
        const collegeAcadOrg = obj.owners[0].collegeAcadOrg;
        const hasValidSubplans = obj.subplans && obj.subplans.length > 0;

        if (hasValidSubplans) {
          for (let i = 0; i < obj.subplans.length; i++) {
            const subplan = obj.subplans[i];
            subplan.isActive = false;
            if (subplanActivationList && subplanActivationList !== null) {
              if (
                subplanActivationList[obj.acadPlanCode] &&
                subplanActivationList[obj.acadPlanCode][subplan.acadSubPlanCode]
              ) {
                subplan.isActive =
                  subplanActivationList[obj.acadPlanCode][
                    subplan.acadSubPlanCode
                  ] === true;
              }
            }
          }

          if (!result.has(collegeAcadOrg)) {
            result.set(collegeAcadOrg, []);
          }

          result.get(collegeAcadOrg).push(obj);
        }
      }
    });
  }
  //iterate over the results map and print in console all the keys
  result.forEach((value, key) => {
    //iterate over the value array and for each object o, sort the property o.subplans which is also an array, by subplans[i].description
    value.forEach((o) => {
      o.subplans.sort((a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      });
    });
  });

  return result;
};

const handleUpdateSubplan = async (e) => {
  const value = e.target.value;
  const [plan, subplan, year] = value.split(' ');
  const isChecked = e.target.checked;

  const token = sessionStorage.getItem('pat.jwt.token');
  let response = await subplanActivation({
    year,
    plan,
    subplan,
    active: isChecked,
    token,
  });
  if (!response || response !== 'SETTINGS-SUBPLANACTIVATION') {
    alert('Error updating subplan ', plan, subplan);
  }

  const label = e.target.checked ? 'Active' : 'Inactive';
  const labelElement = document.querySelector(`label[for="${e.target.id}"]`);
  labelElement.textContent = label;
};

function sortByAlphaSortDescription(arr) {
  return arr.sort((a, b) => {
    if (a.alphaSortDescription < b.alphaSortDescription) {
      return -1;
    }
    if (a.alphaSortDescription > b.alphaSortDescription) {
      return 1;
    }
    return 0;
  });
}
