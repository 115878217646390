import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './slices/modalSlice';
import templateReducer from './slices/templateSlice';
import componentReducer from './slices/componentSlice';
import dataListsReducer from './slices/dataListsSlice';
import currentSelectionReducer from './slices/currentSelectionSlice';
import checksheetReducer from './slices/checksheetSlice';
import validationReducer from './slices/validationSlice';
import userReducer from './slices/userSlice';
import courseListReducer from './slices/courseListSlice';
import settingsReducer from './slices/settingsSlice';

export const createStore = ({
  reducer = {
    modal: modalReducer,
    template: templateReducer,
    component: componentReducer,
    dataLists: dataListsReducer,
    currentSelection: currentSelectionReducer,
    checksheet: checksheetReducer,
    validation: validationReducer,
    user: userReducer,
    courseList: courseListReducer,
    settings: settingsReducer,
  },
} = {}) => {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

export const store = createStore();
