// @ts-check

// React
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';

// pages
import Login from 'pages/Login';
import PATHome from 'pages/PATHome';
import Component from 'pages/Component';
import Components from 'pages/Components';
import Template from 'pages/Template';
import Templates from 'pages/Templates';
import Programs from 'pages/Programs';
import Users from 'pages/Users';
import Checksheet from 'pages/Checksheet';
import CourseList from 'pages/CourseList';
import RoutesWrapper from 'components/RoutesWrapper';
// import NavigationSidebar from 'components/NavigationSidebar';
import TemplateSidebar from 'components/TemplateSidebar';
// import ChecksheetValidation from 'components/ChecksheetValidation';
import UserRoute from 'components/UserRoute';
import SubplanActivation from 'pages/SubplanActivation';
// import PATHeader from 'components/PATHeader';

// components
import Header from 'components/Header';
// import Footer from "components/Footer";

// authorization
import ServiceAuthRoute from 'auth/ServiceAuthRoute';
import ServiceAuthProvider from 'auth/ServiceAuthProvider';

// style sheets
import './App.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ScrollToTop from 'components/ScrollToTop';
import Settings from 'pages/Settings';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const NavigationSidebarLayout = () => {
  return (
    <>
      {/* <NavigationSidebar /> */}
      <div
        className="container-xl"
        // style={{ maxWidth: '1500px' }}
      >
        <Outlet />
      </div>
    </>
  );
};

const ComponentLibraryLayout = () => {
  return (
    <>
      <TemplateSidebar />
      <div className="d-flex justify-content-end">
        <div className="bg-white" style={{ width: '80%' }}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

// const ChecksheetValidationLayout = () => {
//   return (
//     <>
//       <ChecksheetValidation />
//       <div className="d-flex justify-content-end">
//         <div className="bg-white" style={{ width: '80%' }}>
//           <Outlet />
//         </div>
//       </div>
//     </>
//   );
// };

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          {/* Not sure this is the best way for scrolling to top but it works for now*/}
          <ScrollToTop />
          <ServiceAuthProvider>
            <Header />
            <RoutesWrapper>
              {/* <PATHeader /> */}
              <Routes>
                <Route
                  element={
                    <ServiceAuthRoute>
                      <NavigationSidebarLayout />
                    </ServiceAuthRoute>
                  }
                >
                  <Route path="/" element={<PATHome />} />
                  <Route path="/programs" element={<Programs />} />
                  <Route
                    path="/templates"
                    element={
                      <UserRoute
                        allowProvost={true}
                        allowCollege={false}
                        allowDepartment={false}
                        allowDars={false}
                      >
                        <Templates />
                      </UserRoute>
                    }
                  />
                </Route>
                <Route element={<ComponentLibraryLayout />}>
                  <Route
                    path="/template"
                    element={
                      <ServiceAuthRoute>
                        <UserRoute
                          allowProvost={true}
                          allowCollege={false}
                          allowDepartment={false}
                          allowDars={false}
                        >
                          <Template />
                        </UserRoute>
                      </ServiceAuthRoute>
                    }
                  />
                </Route>
                <Route
                  path="/courselist"
                  element={
                    <ServiceAuthRoute>
                      <CourseList />
                    </ServiceAuthRoute>
                  }
                />
                <Route
                  path="/component"
                  element={
                    <ServiceAuthRoute>
                      <UserRoute
                        allowProvost={true}
                        allowCollege={false}
                        allowDepartment={false}
                        allowDars={false}
                      >
                        <Component />
                      </UserRoute>
                    </ServiceAuthRoute>
                  }
                />
                <Route
                  path="/components"
                  element={
                    <ServiceAuthRoute>
                      <UserRoute
                        allowProvost={true}
                        allowCollege={false}
                        allowDepartment={false}
                        allowDars={false}
                      >
                        <Components />
                      </UserRoute>
                    </ServiceAuthRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ServiceAuthRoute>
                      <UserRoute
                        allowProvost={true}
                        allowCollege={false}
                        allowDepartment={false}
                        allowDars={false}
                      >
                        <Users />
                      </UserRoute>
                    </ServiceAuthRoute>
                  }
                />
                <Route
                  path="/subplanactivation"
                  element={
                    <ServiceAuthRoute>
                      <UserRoute
                        allowProvost={true}
                        allowCollege={false}
                        allowDepartment={false}
                        allowDars={false}
                      >
                        <SubplanActivation />
                      </UserRoute>
                    </ServiceAuthRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ServiceAuthRoute>
                      <UserRoute
                        allowProvost={true}
                        allowCollege={false}
                        allowDepartment={false}
                        allowDars={false}
                      >
                        <Settings />
                      </UserRoute>
                    </ServiceAuthRoute>
                  }
                />
                {/* <Route element={<ChecksheetValidationLayout />}> */}
                <Route
                  path="/checksheet"
                  element={
                    <ServiceAuthRoute>
                      <Checksheet />
                    </ServiceAuthRoute>
                  }
                />
                {/* </Route> */}
                <Route path="/login" element={<Login />} />
              </Routes>
            </RoutesWrapper>
            {/* <Footer /> */}
          </ServiceAuthProvider>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
