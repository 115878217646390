import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useServiceAuth from 'auth/useServiceAuth';
import {
  faCircle,
  faEllipsisVertical,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OutsideClickHandler from 'react-outside-click-handler';
import { setCurrentChecksheet } from 'state/slices/currentSelectionSlice';
import { checksheetDelete } from 'apis/checksheetAPIs';
import StaticData from './StaticData';
// import { Button } from '@asu/components-core';
import { classNames } from 'utils';
import { Modal } from 'react-bootstrap';
import NewChecksheet from './NewChecksheetForm';
import CopyChecksheet from './CopyChecksheetForm';

const statusCircle = {
  DEPARTMENT: {
    color: 'text-gray-4',
  },
  COLLEGE: {
    color: 'text-gray-7',
  },
  PROVOST: {
    color: 'text-warning',
  },
  DARS: {
    color: 'text-info',
  },
  COMPLETE: {
    color: 'text-success',
  },
};

const ProgramRow = ({
  program,
  activatedSubplans,
  programList,
  checksheetList,
  filters,
  index,
  refetch,
  isLoading,
  handleOpenDefinitions,
  user,
}) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [checksheetFound, setChecksheetFound] = useState(null);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [checksheetStatus, setChecksheetStatus] = useState(null);
  const [showSubplans, setShowSubplans] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNewChecksheet, setNewChecksheet] = useState(false);

  // const checksheetList = useSelector((state) => state.dataLists.checksheetList);

  const degree = program.degreeDescriptionShort
    ? program.degreeDescriptionShort === 'CERT'
      ? ', Certificate'
      : `, ${program.degreeDescriptionShort}`
    : program.acadPlanType === 'MIN'
      ? ', Minor'
      : null;

  // if (!!checksheetFound) {
  //   user = checksheetFound.updatedBy;
  //   date = new Date(checksheetFound.updatedOn).toLocaleDateString('en-us', {
  //     month: 'short',
  //     day: 'numeric',
  //     year: 'numeric',
  //   });
  // }

  const campusesOffered = [];
  if (program.campusesOffered && program.campusesOffered.length > 0) {
    program.campusesOffered.forEach((campus) => {
      const obj = { ...campus };
      const campusFound = StaticData.campusUndergradList.find(
        (campusUndergrad) => campusUndergrad.value === campus.campusCode
      );
      if (campusFound?.label) obj.label = campusFound.label;
      campusesOffered.push(obj);
    });
  }

  const owners = [];
  let mainOwner = {};
  if (program.owners.length > 0) {
    let highestPercent = 0;
    program.owners.forEach((programOwner) => {
      if (
        owners.filter(
          (owner) => owner.collegeAcadOrg === programOwner.collegeAcadOrg
        ).length === 0
      ) {
        owners.push({
          collegeAcadOrg: programOwner.collegeAcadOrg,
          collegeDescription: programOwner.collegeDescription,
          departmentAcadOrg: programOwner.departmentAcadOrg,
          departmentDescription: programOwner.departmentDescription,
        });
      }

      if (programOwner.percentOwned > highestPercent) {
        highestPercent = programOwner.percentOwned;
        mainOwner = programOwner;
      }
    });
  }

  const { mutate: deleteChecksheet } = useMutation({
    mutationFn: checksheetDelete,
    onSuccess: async () => {
      refetch();
    },
  });

  const handleCloseModal = () => setShowModal(false);

  const handleDeleteChecksheet = async (id) => {
    const token = await getAccessToken();
    deleteChecksheet({ id, token });

    setMenuOpen(false);
  };

  const handleOpenCopyChecksheet = (isNewChecksheet) => {
    console.log(program);
    dispatch(
      setCurrentChecksheet({
        program,
        owners,
        mainOwner,
        campusesOffered,
        checksheet: { ...checksheetFound },
        isNewChecksheet,
      })
    );

    setMenuOpen(false);
    setNewChecksheet(isNewChecksheet);
    setShowModal(true);
  };

  useEffect(() => {
    const found = checksheetList.find(
      (checksheet) =>
        checksheet.id.split('*')[2] === program.acadPlanCode &&
        (!!program.subplan
          ? checksheet.id.split('*')[3] === program.subplan.acadSubPlanCode
          : checksheet.id.split('*')[3] === 'null')
    );

    setChecksheetFound(found);

    if (found?.status) {
      const statusFound = StaticData.statusList.find(
        (status) => status.value === found.status
      );

      if (!!statusFound) setChecksheetStatus(statusFound.label);
    }

    const isDisabled =
      !!filters.length &&
      ((!!found?.status && !filters.includes(found.status)) ||
        (!found && !filters.includes('NONE')));

    setIsFilterDisabled(isDisabled);
  }, [checksheetList, filters, program.acadPlanCode, program.subplan]);

  useEffect(() => {
    let hasActiveSubplans = false;

    if (!!activatedSubplans && programList) {
      const programsWithSubplans = programList.filter((prog) => {
        if (prog.acadPlanCode === program.acadPlanCode) return true;

        return false;
      });

      programsWithSubplans.forEach((prog) => {
        if (
          prog.subplan?.acadSubPlanCode &&
          activatedSubplans[prog.subplan.acadSubPlanCode]
        )
          hasActiveSubplans = true;
      });

      if (!!filters.length) {
        hasActiveSubplans = false;

        Object.keys(activatedSubplans).forEach((key) => {
          const found = checksheetList.find(
            (checksheet) =>
              checksheet.id.split('*')[2] === program.acadPlanCode &&
              checksheet.id.split('*')[3] === key
          );

          if (
            (found && filters.includes(found.status)) ||
            (!found && filters.includes('NONE'))
          )
            hasActiveSubplans = true;
        });
      }
    }

    setShowSubplans(!program.subplan && hasActiveSubplans);
  }, [
    activatedSubplans,
    checksheetList,
    filters,
    program.acadPlanCode,
    program.subplan,
    programList,
  ]);

  if (!program) return <div>Loading...</div>;

  return (
    <>
      <div>
        <div
          key={program.acadPlanCode}
          className={classNames(
            isFilterDisabled && 'bg-gray-1 text-gray-6 pe-none',
            'row mx-0'
          )}
        >
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              !!program.subplan && 'ms-6',
              'col-auto ps-0 fw-bold ps-1 py-2'
            )}
            style={{ width: !!program.subplan ? 'calc(23% - 3rem)' : '23%' }}
          >
            {!!checksheetFound ? (
              <Link
                className="underline-hover text-gray-7"
                to={`/checksheet?id=${checksheetFound.id}`}
              >
                {program.subplan
                  ? program.subplan.description
                  : program.acadPlanDescription}
                {degree}
              </Link>
            ) : (
              <div
                className={classNames(
                  !user?.role?.includes('PROVOST') && 'pe-none',
                  'underline-hover text-gray-7'
                )}
                onClick={
                  user?.role?.includes('PROVOST')
                    ? () => handleOpenCopyChecksheet(true)
                    : undefined
                }
                role="button"
              >
                {program.subplan
                  ? program.subplan.description
                  : program.acadPlanDescription}
                {degree}
              </div>
            )}
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto py-2'
            )}
            style={{ width: '16%' }}
          >
            {program.subplan
              ? program.subplan.acadSubPlanCode
              : program.acadPlanCode}
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto py-2'
            )}
            style={{ width: '21%' }}
          >
            {
              !isFilterDisabled && mainOwner.departmentDescription
              // owners.map((owner, index) => (
              //   <div
              //     key={owner.departmentAcadOrg}
              //     className={classNames(index !== owners.length - 1 && 'mb-1')}
              //   >
              //     {owner.departmentDescription}
              //   </div>
              // ))
            }
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto py-2'
            )}
            style={{ width: '20%' }}
          >
            {!isFilterDisabled &&
              campusesOffered.map((campus, index) => (
                <span key={campus.campusCode}>
                  {campus.label}
                  {index !== campusesOffered.length - 1 && ', '}
                </span>
              ))}
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto py-2'
            )}
            style={{ width: '18%' }}
          >
            {!isFilterDisabled &&
              (isLoading ? (
                <div>Loading...</div>
              ) : (
                <div>
                  <div onClick={handleOpenDefinitions} role="button">
                    {!!checksheetFound?.status && !!checksheetStatus ? (
                      <div className="d-flex gap-1">
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={statusCircle[checksheetFound.status].color}
                          width={'12px'}
                          style={{
                            marginTop: '4px',
                          }}
                        />
                        {checksheetStatus}
                      </div>
                    ) : (
                      <span style={{ marginLeft: '20px' }}>
                        No checksheet exists
                      </span>
                    )}
                  </div>
                  {!!checksheetFound?.lockedBy &&
                    !!Object.keys(checksheetFound.lockedBy).length && (
                      <div
                        className="d-flex gap-1 align-items-center px-1 mt-1 bg-gray-2 rounded-1"
                        style={{ width: 'fit-content' }}
                      >
                        <FontAwesomeIcon
                          icon={faLock}
                          className="text-gray-6"
                        />
                        <div>Locked by {checksheetFound.lockedBy.asurite}</div>
                      </div>
                    )}
                </div>
              ))}
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto d-flex gap-3 justify-content-end align-items-center'
            )}
            style={{
              height: 'fit-content',
              width: '2%',
              padding: '12px 0 8px',
            }}
          >
            {/* {isLoading ? (
              <div>Loading...</div>
            ) : (
              <div className="flex-grow-1 d-flex justify-content-center">
                {checksheetFound ? (
                  <Button
                    label="View program"
                    color="maroon"
                    size="small"
                    onClick={() =>
                      navigate(`/checksheet?id=${checksheetFound.id}`)
                    }
                  />
                ) : (
                  <Button
                    label="Create checksheet"
                    color="gold"
                    size="small"
                    onClick={() => handleOpenCopyChecksheet(true)}
                  />
                )}
              </div>
            )} */}
            {user.role.includes('PROVOST') && !isFilterDisabled && (
              <OutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
                <div className="position-relative">
                  <div
                    className={classNames(
                      isMenuOpen
                        ? 'visible opacity-100'
                        : 'invisible opacity-0',
                      'mini-menu-container bg-white px-2 py-2 border border-gray-3'
                    )}
                  >
                    <div
                      className={classNames(
                        !checksheetFound && 'pe-none opacity-50',
                        'mini-menu-option text-nowrap px-1 mb-1 rounded-1'
                      )}
                      onClick={() => handleOpenCopyChecksheet(false)}
                      role="button"
                    >
                      Copy checksheet
                    </div>
                    <div
                      className={classNames(
                        !checksheetFound && 'pe-none opacity-50',
                        'mini-menu-option text-nowrap px-1 rounded-1'
                      )}
                      onClick={() => handleDeleteChecksheet(checksheetFound.id)}
                      role="button"
                    >
                      Delete
                    </div>
                  </div>
                  <div
                    className={classNames(
                      isMenuOpen && 'bg-gray-3',
                      'vertical-ellipsis p-1 lh-1 rounded-1'
                    )}
                    onClick={() => setMenuOpen(!isMenuOpen)}
                    role="button"
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </div>
                </div>
              </OutsideClickHandler>
            )}
          </div>
        </div>
        {showSubplans && (
          <div className="d-flex ms-6 mt-1">
            <div
              className="px-1 text-success-custom bg-success-custom"
              style={{
                fontSize: '14px',
              }}
            >
              Active subplans
            </div>
          </div>
        )}
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        {isNewChecksheet ? (
          <NewChecksheet
            programList={programList}
            checksheetList={checksheetList}
            handleClose={handleCloseModal}
          />
        ) : (
          <CopyChecksheet
            programList={programList}
            checksheetList={checksheetList}
            handleClose={handleCloseModal}
          />
        )}
      </Modal>
    </>
  );
};

export default ProgramRow;
