import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { checksheetGetHistory } from 'apis/checksheetAPIs';
import useServiceAuth from 'auth/useServiceAuth';
import {
  faArrowLeft,
  faClipboardCheck,
  faFlagCheckered,
  faQuestion,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from 'utils';
import StaticData from './StaticData';
import { useSelector } from 'react-redux';

const ActionLabel = {
  recall: 'Recall requested',
  return: 'Returned to ',
  submit: 'Submitted to ',
  complete: 'Marked as completed',
};

const ActionIcon = {
  recall: faTriangleExclamation,
  return: faArrowLeft,
  submit: faClipboardCheck,
  complete: faFlagCheckered,
};

const ActionColor = {
  recall: { background: 'bg-asu-pink', color: 'text-white' },
  return: { background: 'bg-warning-custom', color: 'text-warning-custom' },
  submit: { background: 'bg-success-custom', color: 'text-success-custom' },
  complete: { background: 'bg-asu-green', color: 'text-gray-7' },
};

const HistoryItem = ({ item }) => {
  const date = new Date(item.updatedOn).toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <div className="border-bottom py-2 border-gray-4">
      <div className="d-flex gap-1">
        <div
          className={classNames(
            ActionColor[item.progressAction.toLowerCase()]?.background ??
              'bg-gray-2',
            'rounded-circle'
          )}
          style={{ width: '32px', height: '32px' }}
        >
          <FontAwesomeIcon
            icon={ActionIcon[item.progressAction.toLowerCase()] ?? faQuestion}
            className={classNames(
              ActionColor[item.progressAction.toLowerCase()]?.color,
              'm-1'
            )}
            style={{ width: '16px', height: '16px' }}
          />
        </div>
        <div style={{ paddingTop: '4px' }}>
          <div>
            <div className="fw-bold">
              {ActionLabel[item.progressAction] ?? '\u2014'}
              {(item.progressAction === 'submit' ||
                item.progressAction === 'return') && (
                <span>
                  {
                    StaticData.statusRoles.find(
                      (statusRole) => statusRole.value === item.progress
                    ).label
                  }
                </span>
              )}
            </div>
            <div
              className="d-flex gap-1 text-gray-6"
              style={{ fontSize: '14px' }}
            >
              <div>{item.asurite}</div>
              <span className="fw-bold">{'\u00B7'}</span>
              <div>{date}</div>
            </div>
          </div>
          {(item.action === 'PROGRESS-MODIFY' ||
            item.action === 'MODIFY-PROGRESS') &&
            item.note &&
            item.note !== 'None' && (
              <div className="mt-2">
                <span className="fw-bold">Message: </span>
                {item.note}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

const ChecksheetHistory = ({ checksheetId }) => {
  const { getAccessToken } = useServiceAuth();

  const [history, setHistory] = useState([]);
  const [showItems, setShowItems] = useState('all');

  const checksheetState = useSelector((state) => state.checksheet);

  const {
    data: historyData,
    isSuccess: historySuccess,
    refetch: refetchHistory,
  } = useQuery({
    queryKey: ['checksheetHistory', checksheetId],
    queryFn: async () => {
      const token = await getAccessToken();
      return checksheetGetHistory({ id: checksheetId, token });
    },
    enabled: !!checksheetId,
  });

  const showReviews = (item) => {
    return !!(
      (item.action === 'PROGRESS-MODIFY' ||
        item.action === 'MODIFY-PROGRESS') &&
      (item.progressAction === 'submit' ||
        item.progressAction === 'return' ||
        item.progressAction === 'complete')
    );
  };

  const showMessages = (item) => {
    return !!(item.note && item.note !== 'None');
  };

  const showRecalls = (item) => {
    return !!(
      (item.action === 'PROGRESS-MODIFY' ||
        item.action === 'MODIFY-PROGRESS') &&
      item.progressAction === 'recall'
    );
  };

  const sortByDate = (a, b) => {
    const dateA = new Date(a.updatedOn);
    const dateB = new Date(b.updatedOn);
    return dateB - dateA;
  };

  useEffect(() => {
    if (historySuccess) {
      console.log(historyData);
      const updatedHistory = [];

      historyData.forEach((item) => {
        if (
          item.action.S === 'PROGRESS-MODIFY' ||
          item.action.S === 'MODIFY-PROGRESS'
        ) {
          const jsonDescription = JSON.parse(item.description.S);

          const updatedItem = {
            action: item.action.S,
            updatedOn: item.updatedOn.S,
            progress: jsonDescription.progress,
            progressAction: jsonDescription.action,
            asurite: jsonDescription.asurite,
            note: jsonDescription.note,
          };

          updatedHistory.push(updatedItem);
        }
      });

      console.log(updatedHistory);

      setHistory([...updatedHistory]);
    }
  }, [historyData, historySuccess]);

  useEffect(() => {
    refetchHistory();
  }, [checksheetState, refetchHistory]);

  return (
    <div className="d-flex flex-column gap-1 mt-1 overflow-y-hidden">
      <div className="d-flex gap-2 justify-content-between align-items-center px-3">
        <h4>History</h4>
        <div className="d-flex gap-1 align-items-center">
          <div>Show: </div>
          <select
            style={{ width: '167px' }}
            onChange={(e) => setShowItems(e.target.value)}
          >
            <option value="all">All</option>
            {/* <option value="edits">Checksheet edits</option> */}
            <option value="reviews">Submissions and reviews</option>
            <option value="messages">Message included</option>
            <option value="recalls">Recalls</option>
          </select>
        </div>
      </div>
      <div className="scrollbox">
        <div className="scrollbox-content px-3">
          {history
            .filter((item) =>
              showItems === 'all'
                ? true
                : showItems === 'reviews'
                  ? showReviews(item)
                  : showItems === 'messages'
                    ? showMessages(item)
                    : showItems === 'recalls'
                      ? showRecalls(item)
                      : true
            )
            .toSorted(sortByDate)
            .map((item, index) => (
              <HistoryItem key={index} item={item} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ChecksheetHistory;
