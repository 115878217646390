import React, { Fragment, useEffect, useMemo, useState } from 'react';
import StaticData from './StaticData';
import { classNames } from 'utils';

const CourseListDetails = ({
  courseListName,
  courses,
  edit,
  openEditModal = null,
  updateData = null,
  division,
}) => {
  console.log('courseList::::::', courses);

  const requirementsShown = useMemo(
    () => [courses.map((course) => true)],
    [courses]
  );

  const [showList, setShowList] = useState(true);

  const RequirementRow = ({
    req,
    reqIndex,
    edit,
    openEditModal,
    division,
    requirementsShown,
  }) => {
    //filter by division
    let showRequirement = true;

    if (division && division.trim()) {
      showRequirement = false;

      if (req.courses && req.courses.length > 0) {
        req.courses.forEach((course) => {
          if (course.subject && course.number) {
            if (
              (course.number >= 100 &&
                course.number < 200 &&
                division === '100') ||
              (course.number >= 200 &&
                course.number < 300 &&
                division === '200') ||
              (course.number >= 300 &&
                course.number < 400 &&
                division === '300') ||
              (course.number >= 400 &&
                course.number < 500 &&
                division === '400') ||
              (course.number < 300 && division === 'Lower') ||
              (course.number >= 300 && division === 'Upper')
            ) {
              showRequirement = true;
            }
          } else if (
            division === course.division ||
            (division === 'Lower' &&
              (course.division === '100' || course.division === '200')) ||
            (division === 'Upper' &&
              (course.division === '300' || course.division === '400')) ||
            !course.division
          ) {
            showRequirement = true;
          }
        });
      }
    }

    requirementsShown[reqIndex] = showRequirement;

    return (
      <>
        {showRequirement && (
          <div key={reqIndex} className="row py-1 m-0 border-top border-gray-2">
            <div
              style={{
                flexBasis: 'auto',
                width: '100%',
              }}
              className="d-flex gap-2 align-items-center px-0"
            >
              <div style={{ width: '100%' }}>
                {req.itemType === 'req_multiple' &&
                  req.courses.map((course, index) => (
                    <Fragment key={index}>
                      {course.connector && (
                        <div
                          className={classNames(
                            course.connector,
                            course.connector === 'or' && 'ms-0',
                            'my-1 fw-bold rounded-1 text-nowrap'
                          )}
                        >
                          {course.connector}
                        </div>
                      )}
                      <div className="d-flex">
                        <div key={index} style={{ width: '80%' }}>
                          <span className="fw-bold">{course.subject}</span>{' '}
                          {course.number && (
                            <>
                              <span className="fw-bold">{course.number}</span>:{' '}
                            </>
                          )}
                          {course?.topic?.description ? (
                            course.topic.description
                          ) : course.proposedTitle ? (
                            <span>
                              {course.proposedTitle}
                              <span className="text-warning-custom bg-warning-custom fw-bold mx-1 px-1">
                                Proposed
                              </span>
                            </span>
                          ) : (
                            course.description
                          )}
                          {!!course.division && course.division.trim()
                            ? course.division === 'Upper' ||
                              course.division === 'Lower'
                              ? course.division + ' Division '
                              : course.division + '-Level '
                            : ''}
                          {course.group && course.group + ': '}
                          {course.groupIndex !== 'custom_text' && course.text}
                          {course.customText}
                          {course.gs && course.subject && (
                            <span> ({course.gs})</span>
                          )}
                          {course.itemType === 'req_gs' && !!course.gs && (
                            <>
                              {
                                StaticData.gsList.find(
                                  (gs) => gs.value === course.gs
                                ).label
                              }{' '}
                              ({course.gs})
                            </>
                          )}
                          {(course.itemType === 'req_elective' ||
                            course.itemType === 'req_subject') && (
                            <span>Elective</span>
                          )}
                          {course?.recommended?.courses.length > 0 && (
                            <>
                              <br />
                              <span className="ms-3">
                                <span className="fst-italic">Recommended:</span>
                                {course.recommended.courses.map(
                                  (c, ind, array) => (
                                    <span key={ind}>
                                      {ind !== 0 &&
                                        !c.connector?.trim(' ') &&
                                        'or '}
                                      {c.connector} {c.subject} {c.number}
                                      {!!c.proposedTitle && (
                                        <span className="text-warning-custom bg-warning-custom fw-bold mx-1 px-1">
                                          Proposed
                                        </span>
                                      )}{' '}
                                    </span>
                                  )
                                )}
                              </span>
                            </>
                          )}
                        </div>
                        <div className="text-end" style={{ width: '20%' }}>
                          {(course.maxHours && course.hours !== course.maxHours
                            ? `${course.hours} - ${course.maxHours}`
                            : course.hours) ||
                            (course.itemType !== 'req_course' &&
                              course.itemType !== 'req_text' &&
                              'Variable')}
                        </div>
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const sum = requirementsShown.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue ? 1 : 0);
    }, 0);

    setShowList(sum > 0);
  }, [requirementsShown]);

  return (
    <>
      <div className="mt-1 ms-1 ps-3 border-start border-gray-4 border-4 ">
        <div className="row px-0 py-1 m-0 fw-bold border-bottom border-2 border-gray-7">
          <div
            className="ps-0"
            style={{
              flexBasis: 'auto',
              width: '80%',
            }}
          >
            Requirement
          </div>
          <div
            style={{ flexBasis: 'auto', width: '20%' }}
            className="text-end text-nowrap pe-0"
          >
            Credit Hours
          </div>
          {edit && <div style={{ flexBasis: 'auto', width: '6%' }} />}
        </div>
        {courses && courses.length > 0 ? (
          showList ? (
            courses.map(
              (req, reqIndex) =>
                req.itemType === 'req_multiple' && (
                  <RequirementRow
                    key={reqIndex}
                    req={req}
                    reqIndex={reqIndex}
                    edit={edit}
                    openEditModal={openEditModal}
                    division={division}
                    requirementsShown={requirementsShown}
                  />
                )
            )
          ) : (
            <div className="text-gray-6 fst-italic py-1">
              No{' '}
              {!!division &&
                (division === 'Upper' || division === 'Lower'
                  ? division + ' Division'
                  : division + '-Level')}{' '}
              courses found in '{courseListName}'
            </div>
          )
        ) : (
          <div className="text-gray-6 fst-italic py-1">No courses found</div>
        )}
      </div>
    </>
  );
};

export default CourseListDetails;
