import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
// import { Dropdown } from 'react-bootstrap';
// import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  // faPenToSquare,
  faEdit,
  faGripVertical,
  faList,
  faLock,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from '@asu/components-core';

import { classNames, createDoughnutData } from 'utils';
import useServiceAuth from 'auth/useServiceAuth';
import { componentGet } from 'apis/componentAPIs';
import {
  setComponentName,
  setNotes,
  setComponentLoading,
  setSubsections,
  removeRequirement,
  // setComponentSettings,
} from 'state/slices/componentSlice';
import { editTemplateComponent } from 'state/slices/templateSlice';
import StaticData from './StaticData';
import TemplateComponentOptions from './TemplateComponentOptions';
// import DropdownCustomToggle from './DropdownCustomToggle';
import LoadingSkeleton from './LoadingSkeleton';
import { Modal } from 'react-bootstrap';
import ComponentForm from './ComponentForm';
import Multiple from './RequirementMultiple';
import { Fragment } from 'react';
import ChecksheetComponentOptions from './ChecksheetComponentOptions';
import {
  // closestCenter,
  closestCorners,
  DndContext,
  // DragOverlay,
  KeyboardSensor,
  PointerSensor,
  // useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { Doughnut } from 'react-chartjs-2';
import ComponentOptions from './ComponentOptions';
import { setChecksheetRequirementList } from 'state/slices/checksheetSlice';
import { checksheetUpdate } from 'apis/checksheetAPIs';
import CourseListDetails from './CourseListDetails';

Chart.register(ArcElement);

// const Droppable = ({ id, children }) => {
//   const { over, isOver, setNodeRef } = useDroppable({
//     id: id,
//   });

//   return (
//     <div
//       ref={setNodeRef}
//       // className={classNames(isOver && over !== id && 'bg-gray-2 ')}
//     >
//       {children}
//     </div>
//   );
// };

const Sortable = ({ id, children, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: 'white',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      role={!disabled ? 'button' : undefined}
      tabIndex={disabled ? -1 : 0}
      className={classNames(disabled && 'focus-off', 'requirement-drag')}
    >
      {children}
    </div>
  );
};

const ComponentLoadingSkeleton = () => {
  return (
    <div className="mt-5">
      <div className="d-flex justify-content-between mb-2">
        <LoadingSkeleton width={'500px'} height={'40px'} />
        <LoadingSkeleton width={'340px'} height={'40px'} />
      </div>
      <div className="d-flex gap-8 mb-2">
        <LoadingSkeleton width={'100px'} height={'40px'} />
        <LoadingSkeleton width={'170px'} height={'40px'} />
      </div>
      {[...Array(5)].map((item, index) => (
        <LoadingSkeleton key={index} width={'100%'} height={'40px'} />
      ))}
    </div>
  );
};

const RequirementRow = ({
  req,
  reqIndex,
  edit,
  checksheet,
  isLocked = false,
  holdsLock = false,
  subsectionId,
  sectionId = null,
  handleOpenEditModal,
  updateData = null,
  courseLists = null,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const backgroundColor = edit === false && 'lightyellow';

  const removeRow = async () => {
    const confirmDeletion = window.confirm(
      'Are you sure you want to delete this data?'
    );
    if (confirmDeletion) {
      if (checksheet)
        updateData(reqIndex, req.itemType, null, sectionId, subsectionId);
      else
        dispatch(
          removeRequirement({
            subsectionId,
            requirementId: req.requirementId,
          })
        );
    }
  };

  const CourseListAccordion = ({ course }) => {
    const [showCourseList, setShowCourseList] = useState(false);

    console.log('Courses: ', courseLists[course.courseListId]?.courses);

    const handleShowCourseList = (e) => {
      e.stopPropagation();
      setShowCourseList(!showCourseList);
    };

    return (
      <>
        <span className="button-link" onClick={(e) => handleShowCourseList(e)}>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={classNames(showCourseList && 'rotate-180')}
            style={{ margin: '0 4px' }}
          />
          {showCourseList ? 'Hide course list' : 'Show course list'}
        </span>
        {showCourseList && (
          <CourseListDetails
            courseListName={courseLists[course.courseListId]?.courseListName}
            courses={courseLists[course.courseListId]?.courses || []}
            edit={false}
            division={course.division}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div
        role={
          (edit && !checksheet) || (checksheet && isLocked && holdsLock)
            ? 'button'
            : undefined
        }
      >
        {req.itemType === 'req_multiple' && (
          <div
            className={classNames(
              ((edit && !checksheet) ||
                (checksheet && isLocked && holdsLock)) &&
                'hoverable',
              'row py-2 mx-0 border border-top-0 border-gray-3'
            )}
            style={{ backgroundColor: !edit && checksheet && backgroundColor }}
          >
            <div className="d-flex gap-1" style={{ width: '80%' }}>
              {edit &&
              (!checksheet || (checksheet && isLocked && holdsLock)) &&
              !readOnly ? (
                <div className="d-flex gap-2" style={{ width: '6%' }}>
                  <FontAwesomeIcon
                    icon={faGripVertical}
                    className="text-gray-4"
                    style={{ width: '15px' }}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    title="Delete requirement"
                    onClick={removeRow}
                    className="deleteButton text-gray-6"
                  />
                </div>
              ) : (
                checksheet && (
                  <div className="pe-0" style={{ width: '3%' }}>
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                )
              )}

              <div
                onClick={
                  (edit && !checksheet) || (checksheet && isLocked && holdsLock)
                    ? (e) => {
                        e.stopPropagation();

                        if (edit) {
                          handleOpenEditModal(reqIndex, req, subsectionId);
                        } else if (checksheet) {
                          alert(
                            'This requirement was created by the Office of University Provost and cannot be modified'
                          );
                        }
                      }
                    : undefined
                }
                style={{
                  cursor: readOnly ? 'not-allowed' : undefined,
                  width: '94%',
                }} // Visual indication when disabled
              >
                {req.courses.map((course, index) => (
                  <Fragment key={index}>
                    <span>
                      {course.connector && (
                        <>
                          {course.connector !== 'or' && index !== 0 && <br />}
                          <span
                            className={classNames(
                              course.connector,
                              'fw-bold rounded-1 text-nowrap'
                            )}
                          >
                            {course.connector}
                          </span>
                        </>
                      )}
                      <span className={classNames(edit && 'hover-text')}>
                        {course.itemType === 'req_check' &&
                        course.checkType === 'gpa' ? (
                          <>
                            <span className="fw-bold">Check</span>:{' '}
                          </>
                        ) : (
                          course.checkType === 'milestone' && (
                            <>
                              <span className="fw-bold">Milestone</span>:{' '}
                            </>
                          )
                        )}
                        {course.gpa && `Minimum ${course.gpa} `}
                        {course.checkSubtype === 'asu_cum_gpa' &&
                          'ASU Cumulative GPA'}
                        {course.checkSubtype === 'major_gpa' && 'Major GPA'}
                        {(course.checkSubtype === 'subject' ||
                          course.checkSubtype === 'courses') &&
                          `GPA in `}
                        {course.checkSubtype === 'subject' && 'all '}
                        {course.checkSubtype === 'courses' &&
                          course.checkCourses &&
                          course.checkCourses.map(
                            (checkCourse, index, array) => (
                              <span key={index}>
                                {index !== 0 &&
                                  index === array.length - 1 &&
                                  'and '}
                                {checkCourse.subject} {checkCourse.number}
                                {array.length > 2 && index < array.length - 1
                                  ? ', '
                                  : ' '}
                              </span>
                            )
                          )}
                        {course.milestone && (
                          <span>{Object.values(course.milestone)[0]}</span>
                        )}
                        {course.subject && (
                          <span
                            className={classNames(
                              course.itemType !== 'req_check' && 'fw-bold'
                            )}
                          >
                            {course.subject}{' '}
                          </span>
                        )}
                        {course.number && (
                          <>
                            <span className="fw-bold">{course.number}</span>:{' '}
                          </>
                        )}
                        {course?.topic?.description ? (
                          course.topic.description
                        ) : course.proposedTitle ? (
                          <span>
                            {course.proposedTitle}
                            <span className="text-warning-custom bg-warning-custom fw-bold mx-1 px-1">
                              Proposed
                            </span>
                          </span>
                        ) : (
                          course.description
                        )}
                        {!!course.division && course.division.trim()
                          ? (course.division === 'Upper' ||
                            course.division === 'Lower'
                              ? course.division + ' Division'
                              : course.division + '-Level') +
                            (course.itemType !== 'req_course_list' ? ' ' : '')
                          : ''}
                        {course.checkSubtype === 'subject' && ' courses'}
                        {course.group && course.group + ': '}
                        {course.groupIndex !== 'custom_text' && course.text}
                        {course.customText}
                        {course.gs && course.subject && (
                          <span> ({course.gs})</span>
                        )}
                        {course.itemType === 'req_gs' && !!course.gs && (
                          <>
                            {
                              StaticData.gsList.find(
                                (gs) => gs.value === course.gs
                              ).label
                            }{' '}
                            ({course.gs})
                          </>
                        )}
                        {(course.itemType === 'req_elective' ||
                          course.itemType === 'req_subject') &&
                          'Elective'}
                        {!!course?.recommended?.courses?.length && (
                          <>
                            <br />
                            <span style={{ marginLeft: '55px' }}>
                              <span className="fst-italic">Recommended:</span>
                              {course.recommended.courses.map(
                                (c, ind, array) => (
                                  <span key={ind}>
                                    {ind !== 0 &&
                                      !c.connector?.trim(' ') &&
                                      'or '}
                                    {c.connector} {c.subject} {c.number}
                                    {!!c.proposedTitle && (
                                      <span className="text-warning-custom bg-warning-custom fw-bold mx-1 px-1">
                                        Proposed
                                      </span>
                                    )}{' '}
                                  </span>
                                )
                              )}
                            </span>
                          </>
                        )}
                        {courseLists && courseLists[course.courseListId] && (
                          <span className="fw-bold underline-hover mx-1">
                            <FontAwesomeIcon
                              icon={faList}
                              style={{ marginRight: '4px' }}
                            />
                            {courseLists[course.courseListId]?.courseListName ||
                              'Untitled course list'}
                          </span>
                        )}
                      </span>
                      {courseLists && courseLists[course.courseListId] && (
                        <CourseListAccordion course={course} />
                      )}
                    </span>
                  </Fragment>
                ))}
              </div>
            </div>
            <div
              style={{
                flexBasis: 'auto',
                width: '10%',
                fontSize: '14px',
              }}
              className={classNames(
                (!req.grade || !req.grade.trim()) && 'text-gray-4',
                'text-nowrap'
              )}
            >
              {req.grade && req.grade.trim() ? req.grade : '\u2014'}
            </div>
            <div
              style={{ flexBasis: 'auto', width: '10%' }}
              className="d-flex justify-content-end"
            >
              <div
                className={classNames(
                  !!req.hours
                    ? 'bg-gray-2 fw-semibold rounded-pill'
                    : 'text-gray-4',
                  'text-nowrap text-end lh-1'
                )}
                style={{
                  fontSize: '14px',
                  height: 'fit-content',
                  padding: '5px 8px',
                }}
              >
                {!!req.hours ? `${req.hours}` : '\u2014'}
                {!!req.maxHours &&
                  req.maxHours !== 0 &&
                  req.maxHours !== req.hours &&
                  `${' - '} ${req.maxHours}`}
              </div>
            </div>

            {req.notes && (
              <div className="row align-items-center pt-1 m-0">
                <div
                  className="p-0"
                  style={{
                    flexBasis: 'auto',
                    width: '55%',
                    fontSize: '14px',
                  }}
                >
                  {req.notes}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const SubsectionRow = ({
  subsectionId,
  subsectionData,
  checksheetData = null,
  sectionId,
  edit,
  checksheet,
  isLocked = false,
  holdsLock = false,
  handleOpenEditModal,
  updateData,
  updateSubsection,
  courseLists,
  readOnly = false,
}) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [subsectionName, setSubsectionName] = useState(
    checksheet && checksheetData
      ? checksheetData.subsectionName
      : subsectionData?.subsectionName ?? ''
  );
  const [subsectionHours, setSubsectionHours] = useState(
    checksheet && checksheetData?.hours
      ? checksheetData.hours
      : subsectionData?.hours ?? 0
  );
  const [subsectionUpperDivisionHours, setSubsectionUpperDivisionHours] =
    useState(
      checksheet && checksheetData?.upperDivisionHours
        ? checksheetData.upperDivisionHours
        : subsectionData?.upperDivisionHours ?? 0
    );

  const subsections = useSelector((state) => state.component.subsections);
  const totalHoursPerSubsection = useSelector(
    (state) => state.validation.totalHoursPerSubsection
  );
  const totalUpperDivisionHoursPerSubsection = useSelector(
    (state) => state.validation.totalUpperDivisionHoursPerSubsection
  );

  const handleSubsectionNameKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubsectionInput(e);
      inputRef.current.blur(); // To trigger onBlur and remove focus
    }
  };

  const handleSubsectionInput = (e) => {
    let { value, name } = e.target;

    if (value) {
      if (name === 'hours' || name === 'upperDivisionHours') value = +value;

      if (checksheet) {
        updateSubsection(value, subsectionId, sectionId, name);
      } else {
        const next = { ...subsections };
        next[subsectionId] = { ...next[subsectionId] };
        next[subsectionId][name] = value;
        dispatch(setSubsections(next));
      }
    }
  };

  useEffect(() => {
    if (checksheet && checksheetData) {
      setSubsectionName(checksheetData.subsectionName);
      setSubsectionHours(checksheetData.hours);
      setSubsectionUpperDivisionHours(checksheetData.upperDivisionHours);
    } else {
      setSubsectionName(subsectionData?.subsectionName ?? '');
      setSubsectionHours(subsectionData?.hours ?? 0);
      setSubsectionUpperDivisionHours(subsectionData?.upperDivisionHours ?? 0);
    }
  }, [checksheet, checksheetData, subsectionData]);

  return (
    <div>
      <div className="w-100 pb-2">
        <div>
          {edit &&
            (!checksheet || (checksheet && isLocked && holdsLock)) &&
            subsectionId !== sectionId &&
            subsectionId !== '0' && (
              <div className="d-flex justify-content-between">
                <div style={{ width: '50%' }}>
                  <input
                    type="text"
                    className="h4"
                    style={{ width: '80%' }}
                    name="subsectionName"
                    value={subsectionName}
                    onChange={(e) => setSubsectionName(e.target.value)}
                    onBlur={(e) => handleSubsectionInput(e)}
                    onKeyUp={(e) => handleSubsectionNameKeyPress(e)}
                    ref={inputRef}
                    disabled={readOnly}
                  />
                  {!readOnly && (
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => {
                        inputRef.current && inputRef.current.focus();
                      }}
                      className="editButton px-2"
                    />
                  )}
                </div>
                {checksheet
                  ? !readOnly && (
                      <ChecksheetComponentOptions id={subsectionId} />
                    )
                  : edit && (
                      <ComponentOptions
                        id={subsectionId}
                        sectionId={sectionId}
                      />
                    )}
              </div>
            )}
          {!edit && !!subsectionData?.subsectionName ? (
            <h4>{subsectionData.subsectionName}</h4>
          ) : (
            checksheet &&
            !holdsLock &&
            !!subsectionName && <h4>{subsectionName}</h4>
          )}
          <div className="row px-0 py-1 m-0 fw-bold border-bottom border-2 border-gray-7">
            <div
              className="ps-0"
              style={{
                flexBasis: 'auto',
                width: '80%',
              }}
            >
              Requirement
            </div>
            <div
              style={{ flexBasis: 'auto', width: '10%' }}
              className="text-nowrap"
            >
              Min Grade
            </div>
            <div
              style={{ flexBasis: 'auto', width: '10%' }}
              className="text-end text-nowrap pe-0"
            >
              Credit Hours
            </div>
            {edit && <div style={{ flexBasis: 'auto', width: '6%' }} />}
          </div>
        </div>
        {!!subsectionData?.requirements?.length && (
          <SortableContext
            items={subsectionData.requirements.map(
              (req) => `${subsectionId}@${req.requirementId}`
            )}
            strategy={verticalListSortingStrategy}
          >
            {/* =======Requirements added to subsection that is created in template  ====== */}
            {subsectionData.requirements.map((req, reqIndex) => (
              <Sortable
                key={req.requirementId}
                id={`${subsectionId}@${req.requirementId}`}
                disabled={!edit || checksheet}
              >
                <RequirementRow
                  req={req}
                  reqIndex={reqIndex}
                  edit={edit}
                  checksheet={checksheet}
                  isLocked={isLocked}
                  holdsLock={holdsLock}
                  subsectionId={subsectionId}
                  handleOpenEditModal={handleOpenEditModal}
                  readOnly={readOnly}
                />
              </Sortable>
            ))}
          </SortableContext>
        )}
        {/* =======Checksheet Requirements added to subsection that is created in template  ====== */}
        {checksheet && checksheetData?.requirements && (
          <SortableContext
            items={checksheetData.requirements.map(
              (req) => `${subsectionId}@${req.requirementId}`
            )}
            strategy={verticalListSortingStrategy}
          >
            {checksheetData.requirements.map((req, reqIndex) => (
              <Sortable
                key={req.requirementId}
                id={`${subsectionId}@${req.requirementId}`}
                disabled={!holdsLock}
              >
                <RequirementRow
                  req={req}
                  reqIndex={reqIndex}
                  edit={true}
                  checksheet={checksheet}
                  isLocked={isLocked}
                  holdsLock={holdsLock}
                  subsectionId={subsectionId}
                  sectionId={sectionId}
                  handleOpenEditModal={handleOpenEditModal}
                  updateData={updateData}
                  courseLists={courseLists}
                  readOnly={readOnly}
                />
              </Sortable>
            ))}
          </SortableContext>
        )}
        {!subsectionData?.requirements?.length &&
          !checksheetData?.requirements?.length && (
            <div className="row py-2 m-0 border border-top-0 border-gray-3">
              <div className="text-gray-6" style={{ fontSize: '14px' }}>
                No requirements added
              </div>
            </div>
          )}
      </div>
      <div className="d-flex justify-content-between gap-2">
        {((edit && !checksheet) || (checksheet && isLocked && holdsLock)) &&
          !readOnly && (
            <Button
              onClick={() => {
                handleOpenEditModal(9999, {}, subsectionId);
              }}
              color="maroon"
              label="Add requirement"
              icon={['fa', 'plus']}
              size="small"
              style={{ height: 'min-content' }}
            />
          )}
        <div className="d-flex justify-content-end w-100">
          <div>
            <div className="d-flex gap-1 justify-content-end align-items-center">
              {!!subsectionHours && (
                <div style={{ width: '24px' }}>
                  <Doughnut
                    data={createDoughnutData(
                      totalHoursPerSubsection[subsectionId],
                      subsectionHours ?? 0
                    )}
                  />
                </div>
              )}
              <span className="fw-bold">Credit hours subtotal: </span>
              <div className="ms-2">
                <span className="fw-bold">
                  {totalHoursPerSubsection[subsectionId] || 0}
                </span>
                <span className="px-1 text-gray-5">/</span>
                {(edit && !checksheet) ||
                (checksheet && isLocked && holdsLock) ? (
                  <input
                    value={subsectionHours}
                    onChange={(e) => setSubsectionHours(+e.target.value)}
                    name="hours"
                    onBlur={(e) => handleSubsectionInput(e)}
                    type="number"
                    // min="0"
                    // step="1"
                    className="px-0 text-end"
                    disabled={readOnly}
                    style={{
                      width: '50px',
                      height: '20px',
                    }}
                  />
                ) : !!subsectionHours && subsectionHours !== 0 ? (
                  <span>{subsectionHours}</span>
                ) : (
                  <span className="text-gray-4">{'\u2014'}</span>
                )}
              </div>
            </div>

            <div className="d-flex gap-1 justify-content-end align-items-center">
              {!!subsectionUpperDivisionHours && (
                <div style={{ width: '24px' }}>
                  <Doughnut
                    data={createDoughnutData(
                      totalUpperDivisionHoursPerSubsection[subsectionId],
                      subsectionUpperDivisionHours ?? 0
                    )}
                  />
                </div>
              )}
              <span className="fw-bold">
                Upper division credit hours subtotal:
              </span>
              <div className="ms-2">
                <span className="fw-bold">
                  {totalUpperDivisionHoursPerSubsection[subsectionId] || 0}
                </span>
                <span className="px-1 text-gray-5">/</span>
                {(edit && !checksheet) ||
                (checksheet && isLocked && holdsLock) ? (
                  <input
                    value={subsectionUpperDivisionHours}
                    onChange={(e) =>
                      setSubsectionUpperDivisionHours(+e.target.value)
                    }
                    name="upperDivisionHours"
                    onBlur={(e) => handleSubsectionInput(e)}
                    type="number"
                    // min="0"
                    // step="1"
                    className="px-0 text-end"
                    disabled={readOnly}
                    style={{
                      width: '50px',
                      height: '20px',
                    }}
                  />
                ) : !!subsectionUpperDivisionHours &&
                  subsectionUpperDivisionHours !== 0 ? (
                  <span>{subsectionUpperDivisionHours}</span>
                ) : (
                  <span className="text-gray-4">{'\u2014'}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Component = ({
  id, //component id
  year,
  edit = false,
  checksheet = false,
  isLocked = false,
  holdsLock = false,
  openEditModal = null,
  // modalSetAsRequirement,
  dataToModal = null,
  dataToParent = null,
  updateData = null,
  updateSubsection = null,
  readOnly = false,
}) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();
  const subsectionNameInputRef = useRef(null);

  // const [sortBy, setSortBy] = useState(() => sortByRequirement);
  const [showModal, setShowModal] = useState(false);
  const [showNewSubsection, setShowNewSubsection] = useState(false);

  const templateComponentObjects = useSelector(
    (state) => state.template.templateComponentObjects
  );
  const checksheetState = useSelector((state) => state.checksheet);
  const checksheetRequirementList = useSelector(
    (state) => state.checksheet.checksheetRequirementList
  );

  const courseLists = useSelector((state) => state.checksheet.courseLists);
  const componentState = useSelector((state) => state.component);

  const { mutate: updateChecksheet } = useMutation({
    mutationFn: checksheetUpdate,
  });

  const details = useMemo(
    () =>
      !edit
        ? JSON.parse(templateComponentObjects[id].details)
        : {
            year: componentState.year,
            componentName: componentState.componentName,
            notes: componentState.notes,
            settings: componentState.componentSettings,
            subsections: componentState.subsections,
            folder: componentState.folder,
          },
    [componentState, edit, id, templateComponentObjects]
  );

  // console.log('details:========', JSON.stringify(details));

  const checksheetRequirements = useMemo(() => {
    console.log(
      'checksheetRequirementList:========',
      checksheetRequirementList
    );
    if (!checksheet || !checksheetRequirementList) return {};

    const componentSubsections = checksheetRequirementList[id];
    return componentSubsections ?? {};
  }, [id, checksheet, checksheetRequirementList]);

  // const [hours, setHours] = useState(details.settings?.hours ?? 0);
  // const [upperDivisionHours, setUpperDivisionHours] = useState(
  //   details.settings?.upperDivisionHours ?? 0
  // );
  // const [showEditHours, setShowEditHours] = useState(false);
  // const [showEditUpperDivisionHours, setShowEditUpperDivisionHours] =
  //   useState(false);
  const [showEditNotes, setShowEditNotes] = useState(false);
  const [notesInput, setNotesInput] = useState(details.notes);

  // let hoursSum = 0;
  // let upperDivisionHoursSum = 0;

  // details.requirements.forEach((requirement) => {
  //   const hoursValue = parseInt(requirement.hours);

  //   hoursSum += hoursValue;

  //   if (parseInt(requirement.number) >= 300)
  //     upperDivisionHoursSum += hoursValue;
  // });

  // Get existing component
  const { isPending: getComponentIsLoading } = useMutation({
    mutationFn: componentGet,
    onSuccess: async (data) => {
      dispatch(editTemplateComponent({ id: data['sk'], object: data }));
    },
  });

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleCreateSubsection = () => setShowNewSubsection(true);

  const handleOpenEditNotes = () => {
    setNotesInput(details.notes);
    setShowEditNotes(true);
  };

  // // Update existing component in database
  // const { mutate: updateComponent } = useMutation({
  //   mutationFn: componentUpdate,
  //   onSuccess: async (data, { id, token }) => {
  //     getComponent({ id, token });
  //   },
  // });

  // const handleOpenEditHours = () => {
  //   setShowEditHours(true);
  // };

  // const handleCloseEditHours = () => {
  //   setHours(details.settings?.hours ?? 0);
  //   setShowEditHours(false);
  // };

  // const handleSetHours = (e) => {
  //   setHours(+e.target.value);
  // };

  // const handleOpenEditUpperDivisionHours = () => {
  //   // dispatch(setCurrentComponent(component));
  //   setShowEditUpperDivisionHours(true);
  // };

  // const handleCloseEditUpperDivisionHours = () => {
  //   setUpperDivisionHours(details.settings?.upperDivisionHours ?? 0);
  //   setShowEditUpperDivisionHours(false);
  // };

  // const handleSetUpperDivisionHours = (e) => {
  //   setUpperDivisionHours(+e.target.value);
  // };

  // const handleUpdateComponent = async () => {
  //   if (!edit) {
  //     console.log('These are the new hours: ', hours);
  //     console.log('These are the new upper hours: ', upperDivisionHours);
  //     const jsonData = {
  //       ...details,
  //       settings: {
  //         ...details.settings,
  //         hours,
  //         upperDivisionHours,
  //       },
  //     };

  //     const token = await getAccessToken();
  //     await updateComponent({
  //       id,
  //       jsonData,
  //       token,
  //     });
  //   } else {
  //     dispatch(
  //       setComponentSettings({ ...details.settings, hours, upperDivisionHours })
  //     );
  //   }

  //   setShowEditHours(false);
  //   setShowEditUpperDivisionHours(false);
  // };

  const handleChangeNotes = () => {
    dispatch(setNotes(notesInput));
    setShowEditNotes(false);
  };

  // const handleSortBy = (e) => {
  //   const sort = e.target.value;

  //   if (sort === 'requirement') {
  //     setSortBy(sortByRequirement);
  //   }
  // };

  // Function to handle form input changes
  const handleInputChangeComponentName = (e) => {
    const { value } = e.target;
    dispatch(setComponentName(value));
  };

  const handleSubsectionNameBlur = (e, subsectionId) => {
    const { value } = e.target;
    setShowNewSubsection(false);
    if (value) {
      updateSubsection(value, subsectionId, id, 'subsectionName');
    }
  };

  const receiveDataFromModal = (data) => {
    console.log('Received data from modal-----:' + JSON.stringify(data));
    const subsectionId =
      data.subsectionId && data.subsectionId !== '999'
        ? data.subsectionId
        : data.componentId; // use componentId as subsectionId if subsectionId is not provided (main subsection)

    if (data) {
      updateData(
        data.index,
        data.type,
        data.details,
        data.componentId,
        subsectionId,
        data.oldIndex, // oldIndex is used to remove the old requirement from the list
        data.oldSubsectionId //   OldSubsectionId is used to remove the old requirement from the list
      );
    }
  };

  //add index and req type to data. We need to know the index when data sent back from child component
  //and what type of the requirment it is.
  const createWrappedData = (
    index,
    type,
    details,
    componentId,
    subsectionId,
    oldIndex,
    oldSubsectionId
  ) => {
    const wrappedData = {
      index: index,
      type: type,
      details: details,
      componentId: componentId,
      subsectionId: subsectionId,
      oldIndex: oldIndex,
      oldSubsectionId: oldSubsectionId,
    };
    console.log('wrap data for component:---- ', details);
    return wrappedData;
  };

  const handleOpenEditModal = (index, data, subsectionId) => {
    openEditModal(index, 'req_multiple', data, id, subsectionId);
    if (checksheet) setShowModal(true);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // const handleDragOver = (event) => {
  //   const { active, over } = event;

  //   if (active.id !== over.id) {
  //     let activeIds = active.id.split('@');
  //     let activeSubsectionId = activeIds[0];
  //     let activeReqId = activeIds[1];
  //     let overIds = over.id.split('@');
  //     let overSubsectionId = overIds[0];
  //     let overReqId = overIds[1];
  //     let activeReqIndex, overReqIndex;

  //     console.log('Active subsection: ', activeSubsectionId);
  //     console.log('Over subsection: ', overSubsectionId);
  //     console.log('Active req id: ', activeReqId);
  //     console.log('Over req id: ', overReqId);

  //     if (activeSubsectionId !== overSubsectionId) {
  //       details.subsections[activeSubsectionId].requirements.forEach(
  //         (req, index) => {
  //           if (req.requirementId === activeReqId) {
  //             activeReqIndex = index;
  //           }
  //         }
  //       );

  //       details.subsections[overSubsectionId].requirements.forEach(
  //         (req, index) => {
  //           if (req.requirementId === overReqId) {
  //             overReqIndex = index;
  //           }
  //         }
  //       );

  //       // if (!overSubsectionId) {
  //       //   overSectionId = over.id;
  //       //   // overSubIndex = 0; // Place it as the first item in the section
  //       //   overSubIndex = componentList[overSectionId].length;
  //       // }
  //       if (activeSubsectionId !== overSubsectionId) {
  //         const updatedSubsections = { ...details.subsections };
  //         const activeSubsection = {
  //           ...updatedSubsections[activeSubsectionId],
  //         };
  //         const activeRequirements = [...activeSubsection.requirements];
  //         const overSubsection = { ...updatedSubsections[overSubsectionId] };
  //         const overRequirements = [...overSubsection.requirements];

  //         console.log('Active subsection: ', activeSubsection);
  //         console.log('Over subsection: ', overSubsection);
  //         console.log('Active req index: ', activeReqIndex);
  //         console.log('Over req index: ', overReqIndex);

  //         const updatedActiveRequirements = activeRequirements.splice(
  //           activeReqIndex,
  //           1
  //         )[0];

  //         overRequirements.splice(overReqIndex, 0, updatedActiveRequirements);

  //         activeSubsection.requirements = activeRequirements;
  //         overSubsection.requirements = overRequirements;

  //         updatedSubsections[activeSubsectionId] = activeSubsection;
  //         updatedSubsections[overSubsectionId] = overSubsection;

  //         dispatch(setSubsections(updatedSubsections));
  //       }
  //     }
  //   }
  // };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      let activeIds = active.id.split('@');
      let activeSubsectionId = activeIds[0];
      let activeReqId = activeIds[1];
      let overIds = over.id.split('@');
      let overSubsectionId = overIds[0];
      let overReqId = overIds[1];
      let activeReqIndex, overReqIndex;

      if (activeSubsectionId === overSubsectionId) {
        let isChecksheetRequirement = false;

        if (details?.subsections?.[activeSubsectionId]?.requirements)
          details.subsections[activeSubsectionId].requirements.forEach(
            (req, index) => {
              if (req.requirementId === activeReqId) {
                activeReqIndex = index;
                isChecksheetRequirement = false;
              }
              if (req.requirementId === overReqId) {
                overReqIndex = index;
              }
            }
          );

        if (
          checksheetRequirements?.subsections?.[activeSubsectionId]
            ?.requirements
        )
          checksheetRequirements.subsections[
            activeSubsectionId
          ].requirements.forEach((req, index) => {
            if (req.requirementId === activeReqId) {
              activeReqIndex = index;
              isChecksheetRequirement = true;
            }
            if (req.requirementId === overReqId) {
              overReqIndex = index;
            }
          });

        const updatedSubsections =
          checksheet && isChecksheetRequirement
            ? { ...checksheetRequirements.subsections }
            : { ...details.subsections };
        const activeSubsection = {
          ...updatedSubsections[activeSubsectionId],
        };

        activeSubsection.requirements = arrayMove(
          activeSubsection.requirements,
          activeReqIndex,
          overReqIndex
        );

        updatedSubsections[activeSubsectionId] = activeSubsection;

        if (checksheet && isChecksheetRequirement) {
          const updatedRequirementList = { ...checksheetRequirementList };
          const updatedChecksheetSection = { ...updatedRequirementList[id] };

          updatedChecksheetSection.subsections = updatedSubsections;
          updatedRequirementList[id] = updatedChecksheetSection;
          dispatch(setChecksheetRequirementList(updatedRequirementList));

          const jsonData = {
            year: checksheetState.year.value,
            templateId: checksheetState.templateId,
            checksheetName: checksheetState.checksheetName,
            programType: checksheetState.programType,
            acadplan: checksheetState.acadplan,
            acadsubplan: checksheetState.acadsubplan,
            college: checksheetState.college,
            department: checksheetState.department,
            componentRequirements: updatedRequirementList,
            courseLists: checksheetState.courseLists,
          };

          console.log(jsonData);

          const token = await getAccessToken();

          updateChecksheet({
            id: checksheetState.checksheetId,
            jsonData,
            token,
          });
        } else dispatch(setSubsections(updatedSubsections));
      }
    }
  };

  // function sortByRequirement(a, b) {
  //   if (a.subject < b.subject) {
  //     return -1;
  //   }

  //   if (a.subject > b.subject) {
  //     return 1;
  //   }

  //   if (a.number < b.number) {
  //     return -1;
  //   }

  //   if (a.number > b.number) {
  //     return 1;
  //   }

  //   if (a.description < b.description) {
  //     return -1;
  //   }

  //   if (a.description > b.description) {
  //     return 1;
  //   }

  //   return 0;
  // }

  // useEffect(() => {
  //   setHours(details.settings?.hours ?? 0);
  //   setUpperDivisionHours(details.settings?.upperDivisionHours ?? 0);
  // }, [details]);

  useEffect(() => {
    dispatch(setComponentLoading(getComponentIsLoading));
  }, [dispatch, getComponentIsLoading]);

  useEffect(() => {
    if (showNewSubsection && subsectionNameInputRef.current) {
      subsectionNameInputRef.current.focus();
    }
  }, [showNewSubsection]);

  return (
    <>
      {edit && getComponentIsLoading ? (
        <ComponentLoadingSkeleton />
      ) : (
        <div
          key={id}
          className={classNames(
            !edit && 'border border-gray-4 bg-gray-1 mt-2 p-3'
          )}
        >
          {edit ? (
            <>
              {' '}
              <div className="ps-0 fw-bold">Heading</div>
              <div className="d-flex justify-content-between align-items-center gap-4 pb-4">
                <input
                  type="text"
                  placeholder="Untitled section"
                  className="fs-4 fw-bold px-2 py-1 flex-grow-1"
                  name="componentName"
                  value={details.componentName}
                  onChange={handleInputChangeComponentName}
                  disabled={readOnly}
                />
                {id && !readOnly && (
                  <div className="d-flex gap-2 align-items-center">
                    <Button
                      onClick={() => {
                        handleCreateSubsection();
                      }}
                      color="white"
                      label="Create subsection"
                      size="small"
                      classes={[
                        'btn btn-white btn-md border border-maroon text-maroon',
                      ]}
                    />
                  </div>
                )}
              </div>
            </>
          ) : checksheet ? (
            <div className="d-flex justify-content-between align-items-center gap-4 pb-4">
              <div className="fs-4 fw-bold flex-grow-1">
                {details.componentName}
              </div>
              {!readOnly && isLocked && holdsLock && (
                <div className="d-flex gap-2 align-items-center">
                  <Button
                    onClick={() => {
                      handleCreateSubsection();
                    }}
                    label="Create subsection"
                    size="small"
                    classes={[
                      'btn btn-white btn-md border border-maroon text-maroon',
                    ]}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-between align-items-center pb-4">
              <div className="fs-4 fw-bold lh-1">{details.componentName}</div>
              <div className="d-flex align-items-center gap-2">
                <TemplateComponentOptions id={id} />

                <Button
                  label="Edit section"
                  onClick={handleOpenModal}
                  color="maroon"
                  size="small"
                />
              </div>
            </div>
          )}

          {/* ============Add/Edit notes============== */}
          {!showEditNotes ? (
            !!details.notes ? (
              <div className="mt-1 mb-6">
                <div className="row">
                  <div className="col-9">{details.notes}</div>
                </div>
                {edit && (
                  <div className="d-flex">
                    <div
                      className="button-link"
                      onClick={handleOpenEditNotes}
                      role="button"
                    >
                      Edit
                    </div>
                  </div>
                )}
              </div>
            ) : (
              edit && (
                <div className="d-flex">
                  <div
                    className="button-link mt-2 mb-6"
                    onClick={handleOpenEditNotes}
                    role="button"
                  >
                    Add description/notes
                  </div>
                </div>
              )
            )
          ) : (
            <div className="my-1">
              <textarea
                type="text"
                value={notesInput}
                onChange={(e) => setNotesInput(e.target.value)}
                placeholder="Describe this section or add any notes here"
                rows="3"
                className="p-1"
                style={{ width: '78%' }}
              />
              <div className="d-flex align-items-center gap-2">
                <Button
                  label="Save"
                  color="maroon"
                  size="xsmall"
                  onClick={handleChangeNotes}
                />
                <Button
                  label="Cancel"
                  color="gray"
                  size="xsmall"
                  onClick={() => setShowEditNotes(false)}
                />
              </div>
            </div>
          )}

          {id && (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              // onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              {/* display the main subsection when subsectionId is same as sectionId or temporary subsectionId is '0' */}

              {/* subsectionId, subsectionData, checksheetRequirements, sectionId, edit, checksheet, updateData, handleOpenEditModal */}
              <div
                className={classNames(
                  (Object.entries(details.subsections).length > 1 ||
                    (checksheet &&
                      checksheetRequirements.subsections &&
                      !!Object.keys(checksheetRequirements.subsections).filter(
                        (subsectionId) =>
                          subsectionId !== id && subsectionId !== '0'
                      ).length)) &&
                    'mb-5'
                )}
              >
                <SubsectionRow
                  subsectionId={details.subsections['0'] ? '0' : id}
                  subsectionData={
                    details.subsections['0'] ?? details.subsections[id]
                  }
                  checksheetData={
                    checksheet &&
                    (checksheetRequirements?.subsections?.['0'] ??
                      checksheetRequirements?.subsections?.[id])
                  }
                  sectionId={id}
                  edit={edit}
                  checksheet={checksheet}
                  isLocked={isLocked}
                  holdsLock={holdsLock}
                  handleOpenEditModal={handleOpenEditModal}
                  updateData={updateData}
                  updateSubsection={updateSubsection}
                  courseLists={courseLists}
                  readOnly={readOnly}
                />
              </div>

              {/* ====== subsections   ====== */}
              {Object.keys(details.subsections)
                .filter(
                  (subsectionId) => subsectionId !== id && subsectionId !== '0'
                )
                .map((subsectionId, index, array) => (
                  <div
                    key={subsectionId}
                    className={classNames(
                      (index !== array.length - 1 ||
                        (checksheet &&
                          checksheetRequirements.subsections &&
                          !!Object.keys(
                            checksheetRequirements.subsections
                          ).filter(
                            (subsectionId) =>
                              !Object.keys(details.subsections).includes(
                                subsectionId
                              )
                          ).length)) &&
                        'mb-5'
                    )}
                  >
                    <SubsectionRow
                      subsectionId={subsectionId}
                      subsectionData={details.subsections[subsectionId]}
                      checksheetData={
                        checksheet &&
                        checksheetRequirements?.subsections?.[subsectionId]
                      }
                      sectionId={id}
                      edit={edit}
                      checksheet={checksheet}
                      isLocked={isLocked}
                      holdsLock={holdsLock}
                      handleOpenEditModal={handleOpenEditModal}
                      updateData={updateData}
                      updateSubsection={updateSubsection}
                      courseLists={courseLists}
                      readOnly={readOnly}
                    />
                  </div>
                ))}

              {/* ===========Checksheet subsections ===========  */}
              {checksheet &&
                checksheetRequirements.subsections &&
                Object.keys(checksheetRequirements.subsections)
                  .filter(
                    (subsectionId) =>
                      subsectionId !== id &&
                      subsectionId !== '0' &&
                      !Object.keys(details.subsections).includes(subsectionId)
                  )
                  .map((subsectionId, index, array) => (
                    <div
                      key={subsectionId}
                      className={classNames(
                        index !== array.length - 1 && 'mb-5'
                      )}
                    >
                      <SubsectionRow
                        subsectionId={subsectionId}
                        subsectionData={details.subsections[subsectionId]}
                        checksheetData={
                          checksheetRequirements.subsections[subsectionId]
                        }
                        sectionId={id}
                        edit={true}
                        checksheet={true}
                        isLocked={isLocked}
                        holdsLock={holdsLock}
                        handleOpenEditModal={handleOpenEditModal}
                        updateData={updateData}
                        updateSubsection={updateSubsection}
                        courseLists={courseLists}
                        readOnly={readOnly}
                      />
                    </div>
                  ))}
            </DndContext>
          )}
          {id && showNewSubsection && (
            <input
              type="text"
              className="p-1 fs-4"
              style={{ width: '50%', margin: '7px 0' }}
              name="subsectionName"
              placeholder="Subsection Name"
              onBlur={(e) => handleSubsectionNameBlur(e, '999')}
              ref={subsectionNameInputRef}
              disabled={readOnly}
            />
          )}
        </div>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        {checksheet ? (
          <Multiple
            componentId={id}
            year={year}
            componentName={details.componentName}
            dataFromParent={dataToModal}
            dataToParent={(newData) => receiveDataFromModal(newData)}
            createWrappedData={createWrappedData}
            exitRequirement={handleCloseModal}
            fromChecksheet={true}
            readOnly={readOnly}
            isLocked={isLocked}
            holdsLock={holdsLock}
          />
        ) : (
          <ComponentForm id={id} handleClose={handleCloseModal} />
        )}
      </Modal>
    </>
  );
};

export default Component;
